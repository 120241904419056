/**
 * This file is autogenerated by scripts/generate-obs-typings.js
 * To update this with latest changes do npm run generate:obs-types
 */
import {Merge, JsonArray, JsonObject, JsonValue} from 'type-fest';

export enum WebSocketOpCode {
/**
 * The initial message sent by obs-websocket to newly connected clients.
 *
 * Initial OBS Version: 5.0.0
 */
	Hello = 0,
	/**
	 * The message sent by a newly connected client to obs-websocket in response to a `Hello`.
	 *
	 * Initial OBS Version: 5.0.0
	 */
	Identify = 1,
	/**
	 * The response sent by obs-websocket to a client after it has successfully identified with obs-websocket.
	 *
	 * Initial OBS Version: 5.0.0
	 */
	Identified = 2,
	/**
	 * The message sent by an already-identified client to update identification parameters.
	 *
	 * Initial OBS Version: 5.0.0
	 */
	Reidentify = 3,
	/**
	 * The message sent by obs-websocket containing an event payload.
	 *
	 * Initial OBS Version: 5.0.0
	 */
	Event = 5,
	/**
	 * The message sent by a client to obs-websocket to perform a request.
	 *
	 * Initial OBS Version: 5.0.0
	 */
	Request = 6,
	/**
	 * The message sent by obs-websocket in response to a particular request from a client.
	 *
	 * Initial OBS Version: 5.0.0
	 */
	RequestResponse = 7,
	/**
	 * The message sent by a client to obs-websocket to perform a batch of requests.
	 *
	 * Initial OBS Version: 5.0.0
	 */
	RequestBatch = 8,
	/**
	 * The message sent by obs-websocket in response to a particular batch of requests from a client.
	 *
	 * Initial OBS Version: 5.0.0
	 */
	RequestBatchResponse = 9,
}

/* eslint-disable no-bitwise, @typescript-eslint/prefer-literal-enum-member */
export enum EventSubscription {
/**
 * Subcription value used to disable all events.
 *
 * Initial OBS Version: 5.0.0
 */
	None = 0,
	/**
	 * Subscription value to receive events in the `General` category.
	 *
	 * Initial OBS Version: 5.0.0
	 */
	General = (1 << 0),
	/**
	 * Subscription value to receive events in the `Config` category.
	 *
	 * Initial OBS Version: 5.0.0
	 */
	Config = (1 << 1),
	/**
	 * Subscription value to receive events in the `Scenes` category.
	 *
	 * Initial OBS Version: 5.0.0
	 */
	Scenes = (1 << 2),
	/**
	 * Subscription value to receive events in the `Inputs` category.
	 *
	 * Initial OBS Version: 5.0.0
	 */
	Inputs = (1 << 3),
	/**
	 * Subscription value to receive events in the `Transitions` category.
	 *
	 * Initial OBS Version: 5.0.0
	 */
	Transitions = (1 << 4),
	/**
	 * Subscription value to receive events in the `Filters` category.
	 *
	 * Initial OBS Version: 5.0.0
	 */
	Filters = (1 << 5),
	/**
	 * Subscription value to receive events in the `Outputs` category.
	 *
	 * Initial OBS Version: 5.0.0
	 */
	Outputs = (1 << 6),
	/**
	 * Subscription value to receive events in the `SceneItems` category.
	 *
	 * Initial OBS Version: 5.0.0
	 */
	SceneItems = (1 << 7),
	/**
	 * Subscription value to receive events in the `MediaInputs` category.
	 *
	 * Initial OBS Version: 5.0.0
	 */
	MediaInputs = (1 << 8),
	/**
	 * Subscription value to receive the `VendorEvent` event.
	 *
	 * Initial OBS Version: 5.0.0
	 */
	Vendors = (1 << 9),
	/**
	 * Subscription value to receive events in the `Ui` category.
	 *
	 * Initial OBS Version: 5.0.0
	 */
	Ui = (1 << 10),
	/**
	 * Helper to receive all non-high-volume events.
	 *
	 * Initial OBS Version: 5.0.0
	 */
	All = (General | Config | Scenes | Inputs | Transitions | Filters | Outputs | SceneItems | MediaInputs | Vendors),
	/**
	 * Subscription value to receive the `InputVolumeMeters` high-volume event.
	 *
	 * Initial OBS Version: 5.0.0
	 */
	InputVolumeMeters = (1 << 16),
	/**
	 * Subscription value to receive the `InputActiveStateChanged` high-volume event.
	 *
	 * Initial OBS Version: 5.0.0
	 */
	InputActiveStateChanged = (1 << 17),
	/**
	 * Subscription value to receive the `InputShowStateChanged` high-volume event.
	 *
	 * Initial OBS Version: 5.0.0
	 */
	InputShowStateChanged = (1 << 18),
	/**
	 * Subscription value to receive the `SceneItemTransformChanged` high-volume event.
	 *
	 * Initial OBS Version: 5.0.0
	 */
	SceneItemTransformChanged = (1 << 19),
}
/* eslint-enable no-bitwise, @typescript-eslint/prefer-literal-enum-member */

export enum RequestBatchExecutionType {
/**
 * Not a request batch.
 *
 * Initial OBS Version: 5.0.0
 */
	None = -1,
	/**
	 * A request batch which processes all requests serially, as fast as possible.
	 *
	 * Note: To introduce artificial delay, use the `Sleep` request and the `sleepMillis` request field.
	 *
	 * Initial OBS Version: 5.0.0
	 */
	SerialRealtime = 0,
	/**
	 * A request batch type which processes all requests serially, in sync with the graphics thread. Designed to provide high accuracy for animations.
	 *
	 * Note: To introduce artificial delay, use the `Sleep` request and the `sleepFrames` request field.
	 *
	 * Initial OBS Version: 5.0.0
	 */
	SerialFrame = 1,
	/**
	 * A request batch type which processes all requests using all available threads in the thread pool.
	 *
	 * Note: This is mainly experimental, and only really shows its colors during requests which require lots of
	 * active processing, like `GetSourceScreenshot`.
	 *
	 * Initial OBS Version: 5.0.0
	 */
	Parallel = 2,
}

// WebSocket Message Types
export type IncomingMessage<Type = keyof IncomingMessageTypes> = Type extends keyof IncomingMessageTypes ? {
	op: Type;
	d: IncomingMessageTypes[Type];
} : never;

export type OutgoingMessage<Type = keyof OutgoingMessageTypes> = Type extends keyof OutgoingMessageTypes ? {
	op: Type;
	d: OutgoingMessageTypes[Type];
} : never;

export interface IncomingMessageTypes {
	/**
	 * Message sent from the server immediately on client connection. Contains authentication information if auth is required. Also contains RPC version for version negotiation.
	 */
	[WebSocketOpCode.Hello]: {
		/**
		 * Version number of obs-websocket
		 */
		obsWebSocketVersion: string;
		/**
		 * Version number which gets incremented on each breaking change to the obs-websocket protocol.
		 * It's usage in this context is to provide the current rpc version that the server would like to use.
		 */
		rpcVersion: number;
		/**
		 * Authentication challenge when password is required
		 */
		authentication?: {
			challenge: string;
			salt: string;
		};
	};
	/**
	 * The identify request was received and validated, and the connection is now ready for normal operation.
	 */
	[WebSocketOpCode.Identified]: {
		/**
		 * If rpc version negotiation succeeds, the server determines the RPC version to be used and gives it to the client
		 */
		negotiatedRpcVersion: number;
	};
	/**
	 * An event coming from OBS has occured. Eg scene switched, source muted.
	 */
	[WebSocketOpCode.Event]: EventMessage;
	/**
	 * obs-websocket is responding to a request coming from a client
	 */
	[WebSocketOpCode.RequestResponse]: ResponseMessage;
	/**
	 * obs-websocket is responding to a batch request coming from a client
	 */
	[WebSocketOpCode.RequestBatchResponse]: ResponseBatchMessage;
}

export interface OutgoingMessageTypes {
	/**
	 * Response to Hello message, should contain authentication string if authentication is required, along with PubSub subscriptions and other session parameters.
	 */
	[WebSocketOpCode.Identify]: {
		/**
		 * Version number that the client would like the obs-websocket server to use
		 */
		rpcVersion: number;
		/**
		 * Authentication challenge response
		 */
		authentication?: string;
		/**
		 * Bitmask of `EventSubscription` items to subscribe to events and event categories at will. By default, all event categories are subscribed, except for events marked as high volume. High volume events must be explicitly subscribed to.
		 */
		eventSubscriptions?: number;
	};
	/**
	 * Sent at any time after initial identification to update the provided session parameters.
	 */
	[WebSocketOpCode.Reidentify]: {
		/**
		 * Bitmask of `EventSubscription` items to subscribe to events and event categories at will. By default, all event categories are subscribed, except for events marked as high volume. High volume events must be explicitly subscribed to.
		 */
		eventSubscriptions?: number;
	};
	/**
	 * Client is making a request to obs-websocket. Eg get current scene, create source.
	 */
	[WebSocketOpCode.Request]: RequestMessage;
	/**
	 * Client is making a batch request to obs-websocket.
	 */
	[WebSocketOpCode.RequestBatch]: RequestBatchMessage;
}

type EventMessage<T = keyof OBSEventTypes> = T extends keyof OBSEventTypes ? {
	eventType: T;
	/**
	 * The original intent required to be subscribed to in order to receive the event.
	 */
	eventIntent: number;
	eventData: OBSEventTypes[T];
} : never;

export type RequestMessage<T = keyof OBSRequestTypes> = T extends keyof OBSRequestTypes ? {
	requestType: T;
	requestId: string;
	requestData: OBSRequestTypes[T];
} : never;

export type RequestBatchRequest<T = keyof OBSRequestTypes> = T extends keyof OBSRequestTypes ? OBSRequestTypes[T] extends never ? {
	requestType: T;
	requestId?: string;
} : {
	requestType: T;
	requestId?: string;
	requestData: OBSRequestTypes[T];
} : never;

export type RequestBatchOptions = {
	/**
	 * The mode of execution obs-websocket will run the batch in
	 */
	executionType?: RequestBatchExecutionType;
	/**
	 * Whether obs-websocket should stop executing the batch if one request fails
	 */
	haltOnFailure?: boolean;
};

export type RequestBatchMessage = Merge<RequestBatchOptions, {
	requestId: string;
	requests: RequestBatchRequest[];
}>;

export type ResponseMessage<T = keyof OBSResponseTypes> = T extends keyof OBSResponseTypes ? {
	requestType: T;
	requestId: string;
	requestStatus: {result: true; code: number} | {result: false; code: number; comment: string};
	responseData: OBSResponseTypes[T];
} : never;

export type ResponseBatchMessage = {
	requestId: string;
	results: ResponseMessage[];
};

// Events
export interface OBSEventTypes {
	CurrentSceneCollectionChanging: {
		/**
		 * Name of the current scene collection
		 */
		sceneCollectionName: string;
	};
	CurrentSceneCollectionChanged: {
		/**
		 * Name of the new scene collection
		 */
		sceneCollectionName: string;
	};
	SceneCollectionListChanged: {
		/**
		 * Updated list of scene collections
		 */
		sceneCollections: string[];
	};
	CurrentProfileChanging: {
		/**
		 * Name of the current profile
		 */
		profileName: string;
	};
	CurrentProfileChanged: {
		/**
		 * Name of the new profile
		 */
		profileName: string;
	};
	ProfileListChanged: {
		/**
		 * Updated list of profiles
		 */
		profiles: string[];
	};
	SourceFilterListReindexed: {
		/**
		 * Name of the source
		 */
		sourceName: string;
		/**
		 * Array of filter objects
		 */
		filters: JsonObject[];
	};
	SourceFilterCreated: {
		/**
		 * Name of the source the filter was added to
		 */
		sourceName: string;
		/**
		 * Name of the filter
		 */
		filterName: string;
		/**
		 * The kind of the filter
		 */
		filterKind: string;
		/**
		 * Index position of the filter
		 */
		filterIndex: number;
		/**
		 * The settings configured to the filter when it was created
		 */
		filterSettings: JsonObject;
		/**
		 * The default settings for the filter
		 */
		defaultFilterSettings: JsonObject;
	};
	SourceFilterRemoved: {
		/**
		 * Name of the source the filter was on
		 */
		sourceName: string;
		/**
		 * Name of the filter
		 */
		filterName: string;
	};
	SourceFilterNameChanged: {
		/**
		 * The source the filter is on
		 */
		sourceName: string;
		/**
		 * Old name of the filter
		 */
		oldFilterName: string;
		/**
		 * New name of the filter
		 */
		filterName: string;
	};
	SourceFilterEnableStateChanged: {
		/**
		 * Name of the source the filter is on
		 */
		sourceName: string;
		/**
		 * Name of the filter
		 */
		filterName: string;
		/**
		 * Whether the filter is enabled
		 */
		filterEnabled: boolean;
	};
	ExitStarted: undefined;
	InputCreated: {
		/**
		 * Name of the input
		 */
		inputName: string;
		/**
		 * The kind of the input
		 */
		inputKind: string;
		/**
		 * The unversioned kind of input (aka no `_v2` stuff)
		 */
		unversionedInputKind: string;
		/**
		 * The settings configured to the input when it was created
		 */
		inputSettings: JsonObject;
		/**
		 * The default settings for the input
		 */
		defaultInputSettings: JsonObject;
	};
	InputRemoved: {
		/**
		 * Name of the input
		 */
		inputName: string;
	};
	InputNameChanged: {
		/**
		 * Old name of the input
		 */
		oldInputName: string;
		/**
		 * New name of the input
		 */
		inputName: string;
	};
	InputActiveStateChanged: {
		/**
		 * Name of the input
		 */
		inputName: string;
		/**
		 * Whether the input is active
		 */
		videoActive: boolean;
	};
	InputShowStateChanged: {
		/**
		 * Name of the input
		 */
		inputName: string;
		/**
		 * Whether the input is showing
		 */
		videoShowing: boolean;
	};
	InputMuteStateChanged: {
		/**
		 * Name of the input
		 */
		inputName: string;
		/**
		 * Whether the input is muted
		 */
		inputMuted: boolean;
	};
	InputVolumeChanged: {
		/**
		 * Name of the input
		 */
		inputName: string;
		/**
		 * New volume level in multimap
		 */
		inputVolumeMul: number;
		/**
		 * New volume level in dB
		 */
		inputVolumeDb: number;
	};
	InputAudioBalanceChanged: {
		/**
		 * Name of the affected input
		 */
		inputName: string;
		/**
		 * New audio balance value of the input
		 */
		inputAudioBalance: number;
	};
	InputAudioSyncOffsetChanged: {
		/**
		 * Name of the input
		 */
		inputName: string;
		/**
		 * New sync offset in milliseconds
		 */
		inputAudioSyncOffset: number;
	};
	InputAudioTracksChanged: {
		/**
		 * Name of the input
		 */
		inputName: string;
		/**
		 * Object of audio tracks along with their associated enable states
		 */
		inputAudioTracks: JsonObject;
	};
	InputAudioMonitorTypeChanged: {
		/**
		 * Name of the input
		 */
		inputName: string;
		/**
		 * New monitor type of the input
		 */
		monitorType: string;
	};
	InputVolumeMeters: {
		/**
		 * Array of active inputs with their associated volume levels
		 */
		inputs: JsonObject[];
	};
	MediaInputPlaybackStarted: {
		/**
		 * Name of the input
		 */
		inputName: string;
	};
	MediaInputPlaybackEnded: {
		/**
		 * Name of the input
		 */
		inputName: string;
	};
	MediaInputActionTriggered: {
		/**
		 * Name of the input
		 */
		inputName: string;
		/**
		 * Action performed on the input. See `ObsMediaInputAction` enum
		 */
		mediaAction: string;
	};
	StreamStateChanged: {
		/**
		 * Whether the output is active
		 */
		outputActive: boolean;
		/**
		 * The specific state of the output
		 */
		outputState: string;
	};
	RecordStateChanged: {
		/**
		 * Whether the output is active
		 */
		outputActive: boolean;
		/**
		 * The specific state of the output
		 */
		outputState: string;
	};
	ReplayBufferStateChanged: {
		/**
		 * Whether the output is active
		 */
		outputActive: boolean;
		/**
		 * The specific state of the output
		 */
		outputState: string;
	};
	VirtualcamStateChanged: {
		/**
		 * Whether the output is active
		 */
		outputActive: boolean;
		/**
		 * The specific state of the output
		 */
		outputState: string;
	};
	ReplayBufferSaved: {
		/**
		 * Path of the saved replay file
		 */
		savedReplayPath: string;
	};
	SceneItemCreated: {
		/**
		 * Name of the scene the item was added to
		 */
		sceneName: string;
		/**
		 * Name of the underlying source (input/scene)
		 */
		sourceName: string;
		/**
		 * Numeric ID of the scene item
		 */
		sceneItemId: number;
		/**
		 * Index position of the item
		 */
		sceneItemIndex: number;
	};
	SceneItemRemoved: {
		/**
		 * Name of the scene the item was removed from
		 */
		sceneName: string;
		/**
		 * Name of the underlying source (input/scene)
		 */
		sourceName: string;
		/**
		 * Numeric ID of the scene item
		 */
		sceneItemId: number;
	};
	SceneItemListReindexed: {
		/**
		 * Name of the scene
		 */
		sceneName: string;
		/**
		 * Array of scene item objects
		 */
		sceneItems: JsonObject[];
	};
	SceneItemEnableStateChanged: {
		/**
		 * Name of the scene the item is in
		 */
		sceneName: string;
		/**
		 * Numeric ID of the scene item
		 */
		sceneItemId: number;
		/**
		 * Whether the scene item is enabled (visible)
		 */
		sceneItemEnabled: boolean;
	};
	SceneItemLockStateChanged: {
		/**
		 * Name of the scene the item is in
		 */
		sceneName: string;
		/**
		 * Numeric ID of the scene item
		 */
		sceneItemId: number;
		/**
		 * Whether the scene item is locked
		 */
		sceneItemLocked: boolean;
	};
	SceneItemSelected: {
		/**
		 * Name of the scene the item is in
		 */
		sceneName: string;
		/**
		 * Numeric ID of the scene item
		 */
		sceneItemId: number;
	};
	SceneItemTransformChanged: {
		/**
		 * The name of the scene the item is in
		 */
		sceneName: string;
		/**
		 * Numeric ID of the scene item
		 */
		sceneItemId: number;
		/**
		 * New transform/crop info of the scene item
		 */
		sceneItemTransform: JsonObject;
	};
	SceneCreated: {
		/**
		 * Name of the new scene
		 */
		sceneName: string;
		/**
		 * Whether the new scene is a group
		 */
		isGroup: boolean;
	};
	SceneRemoved: {
		/**
		 * Name of the removed scene
		 */
		sceneName: string;
		/**
		 * Whether the scene was a group
		 */
		isGroup: boolean;
	};
	SceneNameChanged: {
		/**
		 * Old name of the scene
		 */
		oldSceneName: string;
		/**
		 * New name of the scene
		 */
		sceneName: string;
	};
	CurrentProgramSceneChanged: {
		/**
		 * Name of the scene that was switched to
		 */
		sceneName: string;
	};
	CurrentPreviewSceneChanged: {
		/**
		 * Name of the scene that was switched to
		 */
		sceneName: string;
	};
	SceneListChanged: {
		/**
		 * Updated array of scenes
		 */
		scenes: JsonObject[];
	};
	CurrentSceneTransitionChanged: {
		/**
		 * Name of the new transition
		 */
		transitionName: string;
	};
	CurrentSceneTransitionDurationChanged: {
		/**
		 * Transition duration in milliseconds
		 */
		transitionDuration: number;
	};
	SceneTransitionStarted: {
		/**
		 * Scene transition name
		 */
		transitionName: string;
	};
	SceneTransitionEnded: {
		/**
		 * Scene transition name
		 */
		transitionName: string;
	};
	SceneTransitionVideoEnded: {
		/**
		 * Scene transition name
		 */
		transitionName: string;
	};
	StudioModeStateChanged: {
		/**
		 * True == Enabled, False == Disabled
		 */
		studioModeEnabled: boolean;
	};
	VendorEvent: {
		/**
		 * Name of the vendor emitting the event
		 */
		vendorName: string;
		/**
		 * Vendor-provided event typedef
		 */
		eventType: string;
		/**
		 * Vendor-provided event data. {} if event does not provide any data
		 */
		eventData: JsonObject;
	};
}

// Requests and Responses
export interface OBSRequestTypes {
	GetPersistentData: {
		/**
		 * The data realm to select. `OBS_WEBSOCKET_DATA_REALM_GLOBAL` or `OBS_WEBSOCKET_DATA_REALM_PROFILE`
		 */
		realm: string;
		/**
		 * The name of the slot to retrieve data from
		 */
		slotName: string;
	};
	SetPersistentData: {
		/**
		 * The data realm to select. `OBS_WEBSOCKET_DATA_REALM_GLOBAL` or `OBS_WEBSOCKET_DATA_REALM_PROFILE`
		 */
		realm: string;
		/**
		 * The name of the slot to retrieve data from
		 */
		slotName: string;
		/**
		 * The value to apply to the slot
		 */
		slotValue: JsonValue;
	};
	GetSceneCollectionList: never;
	SetCurrentSceneCollection: {
		/**
		 * Name of the scene collection to switch to
		 */
		sceneCollectionName: string;
	};
	CreateSceneCollection: {
		/**
		 * Name for the new scene collection
		 */
		sceneCollectionName: string;
	};
	GetProfileList: never;
	SetCurrentProfile: {
		/**
		 * Name of the profile to switch to
		 */
		profileName: string;
	};
	CreateProfile: {
		/**
		 * Name for the new profile
		 */
		profileName: string;
	};
	RemoveProfile: {
		/**
		 * Name of the profile to remove
		 */
		profileName: string;
	};
	GetProfileParameter: {
		/**
		 * Category of the parameter to get
		 */
		parameterCategory: string;
		/**
		 * Name of the parameter to get
		 */
		parameterName: string;
	};
	SetProfileParameter: {
		/**
		 * Category of the parameter to set
		 */
		parameterCategory: string;
		/**
		 * Name of the parameter to set
		 */
		parameterName: string;
		/**
		 * Value of the parameter to set. Use `null` to delete
		 */
		parameterValue: string;
	};
	GetVideoSettings: never;
	SetVideoSettings: {
		/**
		 * Numerator of the fractional FPS value
		 *
		 * @restrictions >= 1
		 * @defaultValue Not changed
		 */
		fpsNumerator?: number;
		/**
		 * Denominator of the fractional FPS value
		 *
		 * @restrictions >= 1
		 * @defaultValue Not changed
		 */
		fpsDenominator?: number;
		/**
		 * Width of the base (canvas) resolution in pixels
		 *
		 * @restrictions >= 1, <= 4096
		 * @defaultValue Not changed
		 */
		baseWidth?: number;
		/**
		 * Height of the base (canvas) resolution in pixels
		 *
		 * @restrictions >= 1, <= 4096
		 * @defaultValue Not changed
		 */
		baseHeight?: number;
		/**
		 * Width of the output resolution in pixels
		 *
		 * @restrictions >= 1, <= 4096
		 * @defaultValue Not changed
		 */
		outputWidth?: number;
		/**
		 * Height of the output resolution in pixels
		 *
		 * @restrictions >= 1, <= 4096
		 * @defaultValue Not changed
		 */
		outputHeight?: number;
	};
	GetStreamServiceSettings: never;
	SetStreamServiceSettings: {
		/**
		 * Type of stream service to apply. Example: `rtmp_common` or `rtmp_custom`
		 */
		streamServiceType: string;
		/**
		 * Settings to apply to the service
		 */
		streamServiceSettings: JsonObject;
	};
	GetRecordDirectory: never;
	GetSourceFilterList: {
		/**
		 * Name of the source
		 */
		sourceName: string;
	};
	GetSourceFilterDefaultSettings: {
		/**
		 * Filter kind to get the default settings for
		 */
		filterKind: string;
	};
	CreateSourceFilter: {
		/**
		 * Name of the source to add the filter to
		 */
		sourceName: string;
		/**
		 * Name of the new filter to be created
		 */
		filterName: string;
		/**
		 * The kind of filter to be created
		 */
		filterKind: string;
		/**
		 * Settings object to initialize the filter with
		 *
		 * @defaultValue Default settings used
		 */
		filterSettings?: JsonObject;
	};
	RemoveSourceFilter: {
		/**
		 * Name of the source the filter is on
		 */
		sourceName: string;
		/**
		 * Name of the filter to remove
		 */
		filterName: string;
	};
	SetSourceFilterName: {
		/**
		 * Name of the source the filter is on
		 */
		sourceName: string;
		/**
		 * Current name of the filter
		 */
		filterName: string;
		/**
		 * New name for the filter
		 */
		newFilterName: string;
	};
	GetSourceFilter: {
		/**
		 * Name of the source
		 */
		sourceName: string;
		/**
		 * Name of the filter
		 */
		filterName: string;
	};
	SetSourceFilterIndex: {
		/**
		 * Name of the source the filter is on
		 */
		sourceName: string;
		/**
		 * Name of the filter
		 */
		filterName: string;
		/**
		 * New index position of the filter
		 *
		 * @restrictions >= 0
		 */
		filterIndex: number;
	};
	SetSourceFilterSettings: {
		/**
		 * Name of the source the filter is on
		 */
		sourceName: string;
		/**
		 * Name of the filter to set the settings of
		 */
		filterName: string;
		/**
		 * Object of settings to apply
		 */
		filterSettings: JsonObject;
		/**
		 * True == apply the settings on top of existing ones, False == reset the input to its defaults, then apply settings.
		 *
		 * @defaultValue true
		 */
		overlay?: boolean;
	};
	SetSourceFilterEnabled: {
		/**
		 * Name of the source the filter is on
		 */
		sourceName: string;
		/**
		 * Name of the filter
		 */
		filterName: string;
		/**
		 * New enable state of the filter
		 */
		filterEnabled: boolean;
	};
	GetVersion: never;
	GetStats: never;
	BroadcastCustomEvent: {
		/**
		 * Data payload to emit to all receivers
		 */
		eventData: JsonObject;
	};
	CallVendorRequest: {
		/**
		 * Name of the vendor to use
		 */
		vendorName: string;
		/**
		 * The request type to call
		 */
		requestType: string;
		/**
		 * Object containing appropriate request data
		 *
		 * @defaultValue {}
		 */
		requestData?: JsonObject;
	};
	GetHotkeyList: never;
	TriggerHotkeyByName: {
		/**
		 * Name of the hotkey to trigger
		 */
		hotkeyName: string;
	};
	TriggerHotkeyByKeySequence: {
		/**
		 * The OBS key ID to use. See https://github.com/obsproject/obs-studio/blob/master/libobs/obs-hotkeys.h
		 *
		 * @defaultValue Not pressed
		 */
		keyId?: string;
		/**
		 * Object containing key modifiers to apply
		 *
		 * @defaultValue Ignored
		 */
		keyModifiers?: {
			/**
			 * Press Shift
			 *
			 * @defaultValue Not pressed
			 */
			shift?: boolean;
			/**
			 * Press CTRL
			 *
			 * @defaultValue Not pressed
			 */
			control?: boolean;
			/**
			 * Press ALT
			 *
			 * @defaultValue Not pressed
			 */
			alt?: boolean;
			/**
			 * Press CMD (Mac)
			 *
			 * @defaultValue Not pressed
			 */
			command?: boolean;
		};
	};
	Sleep: {
		/**
		 * Number of milliseconds to sleep for (if `SERIAL_REALTIME` mode)
		 *
		 * @restrictions >= 0, <= 50000
		 */
		sleepMillis: number;
		/**
		 * Number of frames to sleep for (if `SERIAL_FRAME` mode)
		 *
		 * @restrictions >= 0, <= 10000
		 */
		sleepFrames: number;
	};
	GetInputList: {
		/**
		 * Restrict the array to only inputs of the specified kind
		 *
		 * @defaultValue All kinds included
		 */
		inputKind?: string;
	};
	GetInputKindList: {
		/**
		 * True == Return all kinds as unversioned, False == Return with version suffixes (if available)
		 *
		 * @defaultValue false
		 */
		unversioned?: boolean;
	};
	GetSpecialInputs: never;
	CreateInput: {
		/**
		 * Name of the scene to add the input to as a scene item
		 */
		sceneName: string;
		/**
		 * Name of the new input to created
		 */
		inputName: string;
		/**
		 * The kind of input to be created
		 */
		inputKind: string;
		/**
		 * Settings object to initialize the input with
		 *
		 * @defaultValue Default settings used
		 */
		inputSettings?: JsonObject;
		/**
		 * Whether to set the created scene item to enabled or disabled
		 *
		 * @defaultValue True
		 */
		sceneItemEnabled?: boolean;
	};
	RemoveInput: {
		/**
		 * Name of the input to remove
		 */
		inputName: string;
	};
	SetInputName: {
		/**
		 * Current input name
		 */
		inputName: string;
		/**
		 * New name for the input
		 */
		newInputName: string;
	};
	GetInputDefaultSettings: {
		/**
		 * Input kind to get the default settings for
		 */
		inputKind: string;
	};
	GetInputSettings: {
		/**
		 * Name of the input to get the settings of
		 */
		inputName: string;
	};
	SetInputSettings: {
		/**
		 * Name of the input to set the settings of
		 */
		inputName: string;
		/**
		 * Object of settings to apply
		 */
		inputSettings: JsonObject;
		/**
		 * True == apply the settings on top of existing ones, False == reset the input to its defaults, then apply settings.
		 *
		 * @defaultValue true
		 */
		overlay?: boolean;
	};
	GetInputMute: {
		/**
		 * Name of input to get the mute state of
		 */
		inputName: string;
	};
	SetInputMute: {
		/**
		 * Name of the input to set the mute state of
		 */
		inputName: string;
		/**
		 * Whether to mute the input or not
		 */
		inputMuted: boolean;
	};
	ToggleInputMute: {
		/**
		 * Name of the input to toggle the mute state of
		 */
		inputName: string;
	};
	GetInputVolume: {
		/**
		 * Name of the input to get the volume of
		 */
		inputName: string;
	};
	SetInputVolume: {
		/**
		 * Name of the input to set the volume of
		 */
		inputName: string;
		/**
		 * Volume setting in mul
		 *
		 * @restrictions >= 0, <= 20
		 * @defaultValue `inputVolumeDb` should be specified
		 */
		inputVolumeMul?: number;
		/**
		 * Volume setting in dB
		 *
		 * @restrictions >= -100, <= 26
		 * @defaultValue `inputVolumeMul` should be specified
		 */
		inputVolumeDb?: number;
	};
	GetInputAudioBalance: {
		/**
		 * Name of the input to get the audio balance of
		 */
		inputName: string;
	};
	SetInputAudioBalance: {
		/**
		 * Name of the input to set the audio balance of
		 */
		inputName: string;
		/**
		 * New audio balance value
		 *
		 * @restrictions >= 0.0, <= 1.0
		 */
		inputAudioBalance: number;
	};
	GetInputAudioSyncOffset: {
		/**
		 * Name of the input to get the audio sync offset of
		 */
		inputName: string;
	};
	SetInputAudioSyncOffset: {
		/**
		 * Name of the input to set the audio sync offset of
		 */
		inputName: string;
		/**
		 * New audio sync offset in milliseconds
		 *
		 * @restrictions >= -950, <= 20000
		 */
		inputAudioSyncOffset: number;
	};
	GetInputAudioMonitorType: {
		/**
		 * Name of the input to get the audio monitor type of
		 */
		inputName: string;
	};
	SetInputAudioMonitorType: {
		/**
		 * Name of the input to set the audio monitor type of
		 */
		inputName: string;
		/**
		 * Audio monitor type
		 */
		monitorType: string;
	};
	GetInputAudioTracks: {
		/**
		 * Name of the input
		 */
		inputName: string;
	};
	SetInputAudioTracks: {
		/**
		 * Name of the input
		 */
		inputName: string;
		/**
		 * Track settings to apply
		 */
		inputAudioTracks: JsonObject;
	};
	GetInputPropertiesListPropertyItems: {
		/**
		 * Name of the input
		 */
		inputName: string;
		/**
		 * Name of the list property to get the items of
		 */
		propertyName: string;
	};
	PressInputPropertiesButton: {
		/**
		 * Name of the input
		 */
		inputName: string;
		/**
		 * Name of the button property to press
		 */
		propertyName: string;
	};
	GetMediaInputStatus: {
		/**
		 * Name of the media input
		 */
		inputName: string;
	};
	SetMediaInputCursor: {
		/**
		 * Name of the media input
		 */
		inputName: string;
		/**
		 * New cursor position to set
		 *
		 * @restrictions >= 0
		 */
		mediaCursor: number;
	};
	OffsetMediaInputCursor: {
		/**
		 * Name of the media input
		 */
		inputName: string;
		/**
		 * Value to offset the current cursor position by
		 */
		mediaCursorOffset: number;
	};
	TriggerMediaInputAction: {
		/**
		 * Name of the media input
		 */
		inputName: string;
		/**
		 * Identifier of the `ObsMediaInputAction` enum
		 */
		mediaAction: string;
	};
	GetVirtualCamStatus: never;
	ToggleVirtualCam: never;
	StartVirtualCam: never;
	StopVirtualCam: never;
	GetReplayBufferStatus: never;
	ToggleReplayBuffer: never;
	StartReplayBuffer: never;
	StopReplayBuffer: never;
	SaveReplayBuffer: never;
	GetLastReplayBufferReplay: never;
	GetRecordStatus: never;
	ToggleRecord: never;
	StartRecord: never;
	StopRecord: never;
	ToggleRecordPause: never;
	PauseRecord: never;
	ResumeRecord: never;
	GetSceneItemList: {
		/**
		 * Name of the scene to get the items of
		 */
		sceneName: string;
	};
	GetGroupSceneItemList: {
		/**
		 * Name of the group to get the items of
		 */
		sceneName: string;
	};
	GetSceneItemId: {
		/**
		 * Name of the scene or group to search in
		 */
		sceneName: string;
		/**
		 * Name of the source to find
		 */
		sourceName: string;
		/**
		 * Number of matches to skip during search. >= 0 means first forward. -1 means last (top) item
		 *
		 * @restrictions >= -1
		 * @defaultValue 0
		 */
		searchOffset?: number;
	};
	CreateSceneItem: {
		/**
		 * Name of the scene to create the new item in
		 */
		sceneName: string;
		/**
		 * Name of the source to add to the scene
		 */
		sourceName: string;
		/**
		 * Enable state to apply to the scene item on creation
		 *
		 * @defaultValue True
		 */
		sceneItemEnabled?: boolean;
	};
	RemoveSceneItem: {
		/**
		 * Name of the scene the item is in
		 */
		sceneName: string;
		/**
		 * Numeric ID of the scene item
		 *
		 * @restrictions >= 0
		 */
		sceneItemId: number;
	};
	DuplicateSceneItem: {
		/**
		 * Name of the scene the item is in
		 */
		sceneName: string;
		/**
		 * Numeric ID of the scene item
		 *
		 * @restrictions >= 0
		 */
		sceneItemId: number;
		/**
		 * Name of the scene to create the duplicated item in
		 *
		 * @defaultValue `sceneName` is assumed
		 */
		destinationSceneName?: string;
	};
	GetSceneItemTransform: {
		/**
		 * Name of the scene the item is in
		 */
		sceneName: string;
		/**
		 * Numeric ID of the scene item
		 *
		 * @restrictions >= 0
		 */
		sceneItemId: number;
	};
	SetSceneItemTransform: {
		/**
		 * Name of the scene the item is in
		 */
		sceneName: string;
		/**
		 * Numeric ID of the scene item
		 *
		 * @restrictions >= 0
		 */
		sceneItemId: number;
		/**
		 * Object containing scene item transform info to update
		 */
		sceneItemTransform: JsonObject;
	};
	GetSceneItemEnabled: {
		/**
		 * Name of the scene the item is in
		 */
		sceneName: string;
		/**
		 * Numeric ID of the scene item
		 *
		 * @restrictions >= 0
		 */
		sceneItemId: number;
	};
	SetSceneItemEnabled: {
		/**
		 * Name of the scene the item is in
		 */
		sceneName: string;
		/**
		 * Numeric ID of the scene item
		 *
		 * @restrictions >= 0
		 */
		sceneItemId: number;
		/**
		 * New enable state of the scene item
		 */
		sceneItemEnabled: boolean;
	};
	GetSceneItemLocked: {
		/**
		 * Name of the scene the item is in
		 */
		sceneName: string;
		/**
		 * Numeric ID of the scene item
		 *
		 * @restrictions >= 0
		 */
		sceneItemId: number;
	};
	SetSceneItemLocked: {
		/**
		 * Name of the scene the item is in
		 */
		sceneName: string;
		/**
		 * Numeric ID of the scene item
		 *
		 * @restrictions >= 0
		 */
		sceneItemId: number;
		/**
		 * New lock state of the scene item
		 */
		sceneItemLocked: boolean;
	};
	GetSceneItemIndex: {
		/**
		 * Name of the scene the item is in
		 */
		sceneName: string;
		/**
		 * Numeric ID of the scene item
		 *
		 * @restrictions >= 0
		 */
		sceneItemId: number;
	};
	SetSceneItemIndex: {
		/**
		 * Name of the scene the item is in
		 */
		sceneName: string;
		/**
		 * Numeric ID of the scene item
		 *
		 * @restrictions >= 0
		 */
		sceneItemId: number;
		/**
		 * New index position of the scene item
		 *
		 * @restrictions >= 0
		 */
		sceneItemIndex: number;
	};
	GetSceneItemBlendMode: {
		/**
		 * Name of the scene the item is in
		 */
		sceneName: string;
		/**
		 * Numeric ID of the scene item
		 *
		 * @restrictions >= 0
		 */
		sceneItemId: number;
	};
	SetSceneItemBlendMode: {
		/**
		 * Name of the scene the item is in
		 */
		sceneName: string;
		/**
		 * Numeric ID of the scene item
		 *
		 * @restrictions >= 0
		 */
		sceneItemId: number;
		/**
		 * New blend mode
		 */
		sceneItemBlendMode: string;
	};
	GetSceneList: never;
	GetGroupList: never;
	GetCurrentProgramScene: never;
	SetCurrentProgramScene: {
		/**
		 * Scene to set as the current program scene
		 */
		sceneName: string;
	};
	GetCurrentPreviewScene: never;
	SetCurrentPreviewScene: {
		/**
		 * Scene to set as the current preview scene
		 */
		sceneName: string;
	};
	CreateScene: {
		/**
		 * Name for the new scene
		 */
		sceneName: string;
	};
	RemoveScene: {
		/**
		 * Name of the scene to remove
		 */
		sceneName: string;
	};
	SetSceneName: {
		/**
		 * Name of the scene to be renamed
		 */
		sceneName: string;
		/**
		 * New name for the scene
		 */
		newSceneName: string;
	};
	GetSceneSceneTransitionOverride: {
		/**
		 * Name of the scene
		 */
		sceneName: string;
	};
	SetSceneSceneTransitionOverride: {
		/**
		 * Name of the scene
		 */
		sceneName: string;
		/**
		 * Name of the scene transition to use as override. Specify `null` to remove
		 *
		 * @defaultValue Unchanged
		 */
		transitionName?: string;
		/**
		 * Duration to use for any overridden transition. Specify `null` to remove
		 *
		 * @restrictions >= 50, <= 20000
		 * @defaultValue Unchanged
		 */
		transitionDuration?: number;
	};
	GetSourceActive: {
		/**
		 * Name of the source to get the active state of
		 */
		sourceName: string;
	};
	GetSourceScreenshot: {
		/**
		 * Name of the source to take a screenshot of
		 */
		sourceName: string;
		/**
		 * Image compression format to use. Use `GetVersion` to get compatible image formats
		 */
		imageFormat: string;
		/**
		 * Width to scale the screenshot to
		 *
		 * @restrictions >= 8, <= 4096
		 * @defaultValue Source value is used
		 */
		imageWidth?: number;
		/**
		 * Height to scale the screenshot to
		 *
		 * @restrictions >= 8, <= 4096
		 * @defaultValue Source value is used
		 */
		imageHeight?: number;
		/**
		 * Compression quality to use. 0 for high compression, 100 for uncompressed. -1 to use "default" (whatever that means, idk)
		 *
		 * @restrictions >= -1, <= 100
		 * @defaultValue -1
		 */
		imageCompressionQuality?: number;
	};
	SaveSourceScreenshot: {
		/**
		 * Name of the source to take a screenshot of
		 */
		sourceName: string;
		/**
		 * Image compression format to use. Use `GetVersion` to get compatible image formats
		 */
		imageFormat: string;
		/**
		 * Path to save the screenshot file to. Eg. `C:\Users\user\Desktop\screenshot.png`
		 */
		imageFilePath: string;
		/**
		 * Width to scale the screenshot to
		 *
		 * @restrictions >= 8, <= 4096
		 * @defaultValue Source value is used
		 */
		imageWidth?: number;
		/**
		 * Height to scale the screenshot to
		 *
		 * @restrictions >= 8, <= 4096
		 * @defaultValue Source value is used
		 */
		imageHeight?: number;
		/**
		 * Compression quality to use. 0 for high compression, 100 for uncompressed. -1 to use "default" (whatever that means, idk)
		 *
		 * @restrictions >= -1, <= 100
		 * @defaultValue -1
		 */
		imageCompressionQuality?: number;
	};
	GetStreamStatus: never;
	ToggleStream: never;
	StartStream: never;
	StopStream: never;
	SendStreamCaption: {
		/**
		 * Caption text
		 */
		captionText: string;
	};
	GetTransitionKindList: never;
	GetSceneTransitionList: never;
	GetCurrentSceneTransition: never;
	SetCurrentSceneTransition: {
		/**
		 * Name of the transition to make active
		 */
		transitionName: string;
	};
	SetCurrentSceneTransitionDuration: {
		/**
		 * Duration in milliseconds
		 *
		 * @restrictions >= 50, <= 20000
		 */
		transitionDuration: number;
	};
	SetCurrentSceneTransitionSettings: {
		/**
		 * Settings object to apply to the transition. Can be `{}`
		 */
		transitionSettings: JsonObject;
		/**
		 * Whether to overlay over the current settings or replace them
		 *
		 * @defaultValue true
		 */
		overlay?: boolean;
	};
	GetCurrentSceneTransitionCursor: never;
	TriggerStudioModeTransition: never;
	SetTBarPosition: {
		/**
		 * New position
		 *
		 * @restrictions >= 0.0, <= 1.0
		 */
		position: number;
		/**
		 * Whether to release the TBar. Only set `false` if you know that you will be sending another position update
		 *
		 * @defaultValue `true`
		 */
		release?: boolean;
	};
	GetStudioModeEnabled: never;
	SetStudioModeEnabled: {
		/**
		 * True == Enabled, False == Disabled
		 */
		studioModeEnabled: boolean;
	};
	OpenInputPropertiesDialog: {
		/**
		 * Name of the input to open the dialog of
		 */
		inputName: string;
	};
	OpenInputFiltersDialog: {
		/**
		 * Name of the input to open the dialog of
		 */
		inputName: string;
	};
	OpenInputInteractDialog: {
		/**
		 * Name of the input to open the dialog of
		 */
		inputName: string;
	};
	GetMonitorList: never;
}

export interface OBSResponseTypes {
	GetPersistentData: {
		/**
		 * Value associated with the slot. `null` if not set
		 */
		slotValue: JsonValue;
	};
	SetPersistentData: undefined;
	GetSceneCollectionList: {
		/**
		 * The name of the current scene collection
		 */
		currentSceneCollectionName: string;
		/**
		 * Array of all available scene collections
		 */
		sceneCollections: string[];
	};
	SetCurrentSceneCollection: undefined;
	CreateSceneCollection: undefined;
	GetProfileList: {
		/**
		 * The name of the current profile
		 */
		currentProfileName: string;
		/**
		 * Array of all available profiles
		 */
		profiles: string[];
	};
	SetCurrentProfile: undefined;
	CreateProfile: undefined;
	RemoveProfile: undefined;
	GetProfileParameter: {
		/**
		 * Value associated with the parameter. `null` if not set and no default
		 */
		parameterValue: string;
		/**
		 * Default value associated with the parameter. `null` if no default
		 */
		defaultParameterValue: string;
	};
	SetProfileParameter: undefined;
	GetVideoSettings: {
		/**
		 * Numerator of the fractional FPS value
		 */
		fpsNumerator: number;
		/**
		 * Denominator of the fractional FPS value
		 */
		fpsDenominator: number;
		/**
		 * Width of the base (canvas) resolution in pixels
		 */
		baseWidth: number;
		/**
		 * Height of the base (canvas) resolution in pixels
		 */
		baseHeight: number;
		/**
		 * Width of the output resolution in pixels
		 */
		outputWidth: number;
		/**
		 * Height of the output resolution in pixels
		 */
		outputHeight: number;
	};
	SetVideoSettings: undefined;
	GetStreamServiceSettings: {
		/**
		 * Stream service type, like `rtmp_custom` or `rtmp_common`
		 */
		streamServiceType: string;
		/**
		 * Stream service settings
		 */
		streamServiceSettings: JsonObject;
	};
	SetStreamServiceSettings: undefined;
	GetRecordDirectory: {
		/**
		 * Output directory
		 */
		recordDirectory: string;
	};
	GetSourceFilterList: {
		/**
		 * Array of filters
		 */
		filters: JsonObject[];
	};
	GetSourceFilterDefaultSettings: {
		/**
		 * Object of default settings for the filter kind
		 */
		defaultFilterSettings: JsonObject;
	};
	CreateSourceFilter: undefined;
	RemoveSourceFilter: undefined;
	SetSourceFilterName: undefined;
	GetSourceFilter: {
		/**
		 * Whether the filter is enabled
		 */
		filterEnabled: boolean;
		/**
		 * Index of the filter in the list, beginning at 0
		 */
		filterIndex: number;
		/**
		 * The kind of filter
		 */
		filterKind: string;
		/**
		 * Settings object associated with the filter
		 */
		filterSettings: JsonObject;
	};
	SetSourceFilterIndex: undefined;
	SetSourceFilterSettings: undefined;
	SetSourceFilterEnabled: undefined;
	GetVersion: {
		/**
		 * Current OBS Studio version
		 */
		obsVersion: string;
		/**
		 * Current obs-websocket version
		 */
		obsWebSocketVersion: string;
		/**
		 * Current latest obs-websocket RPC version
		 */
		rpcVersion: number;
		/**
		 * Array of available RPC requests for the currently negotiated RPC version
		 */
		availableRequests: string[];
		/**
		 * Image formats available in `GetSourceScreenshot` and `SaveSourceScreenshot` requests.
		 */
		supportedImageFormats: string[];
		/**
		 * Name of the platform. Usually `windows`, `macos`, or `ubuntu` (linux flavor). Not guaranteed to be any of those
		 */
		platform: string;
		/**
		 * Description of the platform, like `Windows 10 (10.0)`
		 */
		platformDescription: string;
	};
	GetStats: {
		/**
		 * Current CPU usage in percent
		 */
		cpuUsage: number;
		/**
		 * Amount of memory in MB currently being used by OBS
		 */
		memoryUsage: number;
		/**
		 * Available disk space on the device being used for recording storage
		 */
		availableDiskSpace: number;
		/**
		 * Current FPS being rendered
		 */
		activeFps: number;
		/**
		 * Average time in milliseconds that OBS is taking to render a frame
		 */
		averageFrameRenderTime: number;
		/**
		 * Number of frames skipped by OBS in the render thread
		 */
		renderSkippedFrames: number;
		/**
		 * Total number of frames outputted by the render thread
		 */
		renderTotalFrames: number;
		/**
		 * Number of frames skipped by OBS in the output thread
		 */
		outputSkippedFrames: number;
		/**
		 * Total number of frames outputted by the output thread
		 */
		outputTotalFrames: number;
		/**
		 * Total number of messages received by obs-websocket from the client
		 */
		webSocketSessionIncomingMessages: number;
		/**
		 * Total number of messages sent by obs-websocket to the client
		 */
		webSocketSessionOutgoingMessages: number;
	};
	BroadcastCustomEvent: undefined;
	CallVendorRequest: {
		/**
		 * Object containing appropriate response data. {} if request does not provide any response data
		 */
		responseData: JsonObject;
	};
	GetHotkeyList: {
		/**
		 * Array of hotkey names
		 */
		hotkeys: string[];
	};
	TriggerHotkeyByName: undefined;
	TriggerHotkeyByKeySequence: undefined;
	Sleep: undefined;
	GetInputList: {
		/**
		 * Array of inputs
		 */
		inputs: JsonObject[];
	};
	GetInputKindList: {
		/**
		 * Array of input kinds
		 */
		inputKinds: string[];
	};
	GetSpecialInputs: {
		/**
		 * Name of the Desktop Audio input
		 */
		desktop1: string;
		/**
		 * Name of the Desktop Audio 2 input
		 */
		desktop2: string;
		/**
		 * Name of the Mic/Auxiliary Audio input
		 */
		mic1: string;
		/**
		 * Name of the Mic/Auxiliary Audio 2 input
		 */
		mic2: string;
		/**
		 * Name of the Mic/Auxiliary Audio 3 input
		 */
		mic3: string;
		/**
		 * Name of the Mic/Auxiliary Audio 4 input
		 */
		mic4: string;
	};
	CreateInput: {
		/**
		 * ID of the newly created scene item
		 */
		sceneItemId: number;
	};
	RemoveInput: undefined;
	SetInputName: undefined;
	GetInputDefaultSettings: {
		/**
		 * Object of default settings for the input kind
		 */
		defaultInputSettings: JsonObject;
	};
	GetInputSettings: {
		/**
		 * Object of settings for the input
		 */
		inputSettings: JsonObject;
		/**
		 * The kind of the input
		 */
		inputKind: string;
	};
	SetInputSettings: undefined;
	GetInputMute: {
		/**
		 * Whether the input is muted
		 */
		inputMuted: boolean;
	};
	SetInputMute: undefined;
	ToggleInputMute: {
		/**
		 * Whether the input has been muted or unmuted
		 */
		inputMuted: boolean;
	};
	GetInputVolume: {
		/**
		 * Volume setting in mul
		 */
		inputVolumeMul: number;
		/**
		 * Volume setting in dB
		 */
		inputVolumeDb: number;
	};
	SetInputVolume: undefined;
	GetInputAudioBalance: {
		/**
		 * Audio balance value from 0.0-1.0
		 */
		inputAudioBalance: number;
	};
	SetInputAudioBalance: undefined;
	GetInputAudioSyncOffset: {
		/**
		 * Audio sync offset in milliseconds
		 */
		inputAudioSyncOffset: number;
	};
	SetInputAudioSyncOffset: undefined;
	GetInputAudioMonitorType: {
		/**
		 * Audio monitor type
		 */
		monitorType: string;
	};
	SetInputAudioMonitorType: undefined;
	GetInputAudioTracks: {
		/**
		 * Object of audio tracks and associated enable states
		 */
		inputAudioTracks: JsonObject;
	};
	SetInputAudioTracks: undefined;
	GetInputPropertiesListPropertyItems: {
		/**
		 * Array of items in the list property
		 */
		propertyItems: JsonObject[];
	};
	PressInputPropertiesButton: undefined;
	GetMediaInputStatus: {
		/**
		 * State of the media input
		 */
		mediaState: string;
		/**
		 * Total duration of the playing media in milliseconds. `null` if not playing
		 */
		mediaDuration: number;
		/**
		 * Position of the cursor in milliseconds. `null` if not playing
		 */
		mediaCursor: number;
	};
	SetMediaInputCursor: undefined;
	OffsetMediaInputCursor: undefined;
	TriggerMediaInputAction: undefined;
	GetVirtualCamStatus: {
		/**
		 * Whether the output is active
		 */
		outputActive: boolean;
	};
	ToggleVirtualCam: {
		/**
		 * Whether the output is active
		 */
		outputActive: boolean;
	};
	StartVirtualCam: undefined;
	StopVirtualCam: undefined;
	GetReplayBufferStatus: {
		/**
		 * Whether the output is active
		 */
		outputActive: boolean;
	};
	ToggleReplayBuffer: {
		/**
		 * Whether the output is active
		 */
		outputActive: boolean;
	};
	StartReplayBuffer: undefined;
	StopReplayBuffer: undefined;
	SaveReplayBuffer: undefined;
	GetLastReplayBufferReplay: {
		/**
		 * File path
		 */
		savedReplayPath: string;
	};
	GetRecordStatus: {
		/**
		 * Whether the output is active
		 */
		outputActive: boolean;
		/**
		 * Whether the output is paused
		 */
		ouputPaused: boolean;
		/**
		 * Current formatted timecode string for the output
		 */
		outputTimecode: string;
		/**
		 * Current duration in milliseconds for the output
		 */
		outputDuration: number;
		/**
		 * Number of bytes sent by the output
		 */
		outputBytes: number;
	};
	ToggleRecord: undefined;
	StartRecord: undefined;
	StopRecord: undefined;
	ToggleRecordPause: undefined;
	PauseRecord: undefined;
	ResumeRecord: undefined;
	GetSceneItemList: {
		/**
		 * Array of scene items in the scene
		 */
		sceneItems: JsonObject[];
	};
	GetGroupSceneItemList: {
		/**
		 * Array of scene items in the group
		 */
		sceneItems: JsonObject[];
	};
	GetSceneItemId: {
		/**
		 * Numeric ID of the scene item
		 */
		sceneItemId: number;
	};
	CreateSceneItem: {
		/**
		 * Numeric ID of the scene item
		 */
		sceneItemId: number;
	};
	RemoveSceneItem: undefined;
	DuplicateSceneItem: {
		/**
		 * Numeric ID of the duplicated scene item
		 */
		sceneItemId: number;
	};
	GetSceneItemTransform: {
		/**
		 * Object containing scene item transform info
		 */
		sceneItemTransform: JsonObject;
	};
	SetSceneItemTransform: undefined;
	GetSceneItemEnabled: {
		/**
		 * Whether the scene item is enabled. `true` for enabled, `false` for disabled
		 */
		sceneItemEnabled: boolean;
	};
	SetSceneItemEnabled: undefined;
	GetSceneItemLocked: {
		/**
		 * Whether the scene item is locked. `true` for locked, `false` for unlocked
		 */
		sceneItemLocked: boolean;
	};
	SetSceneItemLocked: undefined;
	GetSceneItemIndex: {
		/**
		 * Index position of the scene item
		 */
		sceneItemIndex: number;
	};
	SetSceneItemIndex: undefined;
	GetSceneItemBlendMode: {
		/**
		 * Current blend mode
		 */
		sceneItemBlendMode: string;
	};
	SetSceneItemBlendMode: undefined;
	GetSceneList: {
		/**
		 * Current program scene
		 */
		currentProgramSceneName: string;
		/**
		 * Current preview scene. `null` if not in studio mode
		 */
		currentPreviewSceneName: string;
		/**
		 * Array of scenes
		 */
		scenes: JsonObject[];
	};
	GetGroupList: {
		/**
		 * Array of group names
		 */
		groups: string[];
	};
	GetCurrentProgramScene: {
		/**
		 * Current program scene
		 */
		currentProgramSceneName: string;
	};
	SetCurrentProgramScene: undefined;
	GetCurrentPreviewScene: {
		/**
		 * Current preview scene
		 */
		currentPreviewSceneName: string;
	};
	SetCurrentPreviewScene: undefined;
	CreateScene: undefined;
	RemoveScene: undefined;
	SetSceneName: undefined;
	GetSceneSceneTransitionOverride: {
		/**
		 * Name of the overridden scene transition, else `null`
		 */
		transitionName: string;
		/**
		 * Duration of the overridden scene transition, else `null`
		 */
		transitionDuration: number;
	};
	SetSceneSceneTransitionOverride: undefined;
	GetSourceActive: {
		/**
		 * Whether the source is showing in Program
		 */
		videoActive: boolean;
		/**
		 * Whether the source is showing in the UI (Preview, Projector, Properties)
		 */
		videoShowing: boolean;
	};
	GetSourceScreenshot: {
		/**
		 * Base64-encoded screenshot
		 */
		imageData: string;
	};
	SaveSourceScreenshot: {
		/**
		 * Base64-encoded screenshot
		 */
		imageData: string;
	};
	GetStreamStatus: {
		/**
		 * Whether the output is active
		 */
		outputActive: boolean;
		/**
		 * Whether the output is currently reconnecting
		 */
		outputReconnecting: boolean;
		/**
		 * Current formatted timecode string for the output
		 */
		outputTimecode: string;
		/**
		 * Current duration in milliseconds for the output
		 */
		outputDuration: number;
		/**
		 * Number of bytes sent by the output
		 */
		outputBytes: number;
		/**
		 * Number of frames skipped by the output's process
		 */
		outputSkippedFrames: number;
		/**
		 * Total number of frames delivered by the output's process
		 */
		outputTotalFrames: number;
	};
	ToggleStream: {
		/**
		 * New state of the stream output
		 */
		outputActive: boolean;
	};
	StartStream: undefined;
	StopStream: undefined;
	SendStreamCaption: undefined;
	GetTransitionKindList: {
		/**
		 * Array of transition kinds
		 */
		transitionKinds: string[];
	};
	GetSceneTransitionList: {
		/**
		 * Name of the current scene transition. Can be null
		 */
		currentSceneTransitionName: string;
		/**
		 * Kind of the current scene transition. Can be null
		 */
		currentSceneTransitionKind: string;
		/**
		 * Array of transitions
		 */
		transitions: JsonObject[];
	};
	GetCurrentSceneTransition: {
		/**
		 * Name of the transition
		 */
		transitionName: string;
		/**
		 * Kind of the transition
		 */
		transitionKind: string;
		/**
		 * Whether the transition uses a fixed (unconfigurable) duration
		 */
		transitionFixed: boolean;
		/**
		 * Configured transition duration in milliseconds. `null` if transition is fixed
		 */
		transitionDuration: number;
		/**
		 * Whether the transition supports being configured
		 */
		transitionConfigurable: boolean;
		/**
		 * Object of settings for the transition. `null` if transition is not configurable
		 */
		transitionSettings: JsonObject;
	};
	SetCurrentSceneTransition: undefined;
	SetCurrentSceneTransitionDuration: undefined;
	SetCurrentSceneTransitionSettings: undefined;
	GetCurrentSceneTransitionCursor: {
		/**
		 * Cursor position, between 0.0 and 1.0
		 */
		transitionCursor: number;
	};
	TriggerStudioModeTransition: undefined;
	SetTBarPosition: undefined;
	GetStudioModeEnabled: {
		/**
		 * Whether studio mode is enabled
		 */
		studioModeEnabled: boolean;
	};
	SetStudioModeEnabled: undefined;
	OpenInputPropertiesDialog: undefined;
	OpenInputFiltersDialog: undefined;
	OpenInputInteractDialog: undefined;
	GetMonitorList: {
		/**
		 * a list of detected monitors with some information
		 */
		monitors: JsonObject[];
	};
}
